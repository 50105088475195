import React from 'react';

import { Component } from './component';
import { VIEWER_ITEM_DATA_RAW } from '../../data/viewer/constant';
import { getViewerList } from '../viewer-list';
import { useMutation } from 'react-query';

import { convertViewerToExcelList } from '../../data/viewer/convert';
import { exportToExcel } from '../../lib/util/exportToExcel';

export const Container: React.FC<{
  setValue: Function;
  value: string;
  search: string;
  setSearch: Function;
  setRange: Function;
  // setSession: Function;
  // session: string;
  sessionId: string;
  online: boolean;
  setOnline: Function;
  setDate: Function;
  date: any;
  range: string | null;
}> = ({
  setValue,
  value,
  search,
  setSearch,
  setRange,
  range,
  // session,
  // setSession,
  sessionId,
  online,
  setOnline,
  date,
  setDate,
}) => {
  const onSuccess = (d: any) => {
    const data: VIEWER_ITEM_DATA_RAW[] = d as unknown as VIEWER_ITEM_DATA_RAW[];

    return exportToExcel(convertViewerToExcelList(data), 'Viewer list');
  };
  const action = useMutation(
    () =>
      getViewerList({
        category: value,
        sessionId,
        search,
        date,
        range,
      }),
    { onSuccess },
  );

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const handleExport = () => {
    action.mutate();
  };
  return (
    <Component
      value={value}
      setValue={setValue}
      // session={session}
      // setSession={setSession}
      search={search}
      setSearch={setSearch}
      setRange={setRange}
      sessionId={sessionId}
      isLoading={isLoading()}
      handleExport={handleExport}
      online={online}
      setOnline={setOnline}
      setDate={setDate}
      date={date}
    />
  );
};
