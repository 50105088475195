import React, { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';

import { ACTION_ERROR_INTER, CALENDAR, MODULE_NAME } from './constant';
import { Component } from './component';
import { convertPersonList } from '../../data/person/convert';
import { PERSON_ITEM_DATA_RAW } from '../../data/person/constant';
import { action } from './action';

export const Container: React.FC<{
  sessionId: string;
}> = ({ sessionId }) => {
  const [sort, setSort] = useState('');
  const [search, setSearch] = useState('');

  const preFetch = useInfiniteQuery({
    queryKey: [MODULE_NAME, sessionId],
    queryFn: ({ pageParam = 1 }) =>
      action({
        pageParam,
        sort: sort,
        // session,
        sessionId,
        search,
      }),
    getNextPageParam: (lastPage: any, allPages: any) => {
      return lastPage.length ? allPages.length + 1 : 1;
    },
    retry: 0,
    refetchInterval: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const data = useMemo(() => {
    return convertPersonList(
      preFetch?.data?.pages.reduce((acc: any, page: any) => {
        return [...acc, ...page];
      }, []) as PERSON_ITEM_DATA_RAW[],
    );
  }, [preFetch?.data]);

  const isError = () => {
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = preFetch.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
  };

  useEffect(() => {
    preFetch.refetch();
  }, [sort, search]);
  return (
    <Component
      sort={sort}
      setSort={setSort}
      // session={session}
      // setSession={setSession}
      search={search}
      setSearch={setSearch}
      data={data}
      isError={isError()}
      errorMessage={getErrorMessage()}
      fetchNextPage={preFetch.fetchNextPage}
      isFetching={preFetch.isFetching}
      sessionId={sessionId}
      hasNextPage={preFetch.hasNextPage}
    />
  );
};
