import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { LoaderElem } from '../../common/loader';
import { ACTION_ERROR_INTER } from './constant';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import stopIcon from '../../asset/svg/event/stop.svg';
import startIcon from '../../asset/svg/event/start.svg';
import { actionStar, actionSto } from './action';

type ContainerProps = {
  data: {
    isActive: boolean;
    id: string;
  };
};

export const Container: React.FC<ContainerProps> = ({ data }) => {
  const [isActive, setIsActive] = useState(data.isActive);

  const actionStart = useMutation(() => actionStar(data.id), {
    onSuccess: (newData: any) => {
      console.log(newData);
      setIsActive(newData.isActive); // обновляем состояние `isActive`
    },
  });

  const actionStop = useMutation(() => actionSto(data.id), {
    onSuccess: (newData: any) => {
      console.log(newData);

      setIsActive(newData.isActive); // обновляем состояние `isActive`
    },
  });

  const isLoading = actionStart.isLoading || actionStop.isLoading;

  const actionHandleStart = () => {
    actionStart.mutate();
  };

  const actionHandleStop = () => {
    actionStop.mutate();
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER =
      (actionStop.error as ACTION_ERROR_INTER) ||
      (actionStart.error as ACTION_ERROR_INTER);
    return error ? error.message : '';
  };

  const isError =
    (actionStart.isError || actionStop.isError) &&
    !isLoading &&
    getErrorMessage();

  return (
    <>
      {isLoading && <LoaderElem />}

      {isActive ? (
        <ButtonElem
          iconRight={stopIcon}
          fill="clear"
          disabled={isLoading}
          iconSize={24}
          onClick={actionHandleStop}
        />
      ) : (
        <ButtonElem
          iconRight={startIcon}
          fill="clear"
          disabled={isLoading}
          iconSize={24}
          onClick={actionHandleStart}
        />
      )}
      {isError && <AlertActionElem tid={getErrorMessage()} />}
    </>
  );
};
