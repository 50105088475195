import React, { ChangeEvent, forwardRef, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { TextElem } from '../text';
import { PROPS_TYPE } from './constant';
import { i18n } from '../../lib/lang';

export const Elem = forwardRef<HTMLTextAreaElement, PROPS_TYPE>(
  ({
    title,
    error,
    errorMessage,
    onChange,
    onBlur,
    value,
    name,
    type,
    disabled,
    icon,
    placeholder,
    className,
    autoHeight = false,
    ...props
  }) => {
    const refIcon = useRef<HTMLDivElement>(null);

    const handleChange = (e: any) => {
      if (!e.target) e.target = {};

      e.target.name = name;
      e.target.value = e.target.value;

      if (onChange) {
        onChange(e);
      }
    };

    const handleBlur = (e: any) => {
      if (!e.target) e.target = {};

      e.target.name = name;
      e.target.value = e.target.value;

      if (onBlur) {
        onBlur(e);
      }
    };

    const iconSize = refIcon?.current?.clientWidth || 40;

    const ref = useRef<HTMLTextAreaElement>(null);

    const handleInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
      if (autoHeight && ref && typeof ref === 'object' && ref.current) {
        if (e.target.value == '') {
          ref.current.style.height = '46px';
        } else {
          ref.current.style.height = 'auto';

          // Устанавливаем высоту в зависимости от содержимого
          const newHeight = e.target.value
            ? `${e.target.scrollHeight + 6}px`
            : '46px'; // Минимальная высота 46px
          ref.current.style.height = newHeight;

          // Прокрутка вниз
          ref.current.scrollTop = ref.current.scrollHeight;
        }
      }
      if (props.handleInput) props.handleInput(e);
    };

    useEffect(() => {
      if (autoHeight && ref.current) {
        ref.current!.style.height = '46px';
      }
    }, []);

    useEffect(() => {
      if (autoHeight && ref.current && value == '') {
        setTimeout(() => {
          ref.current!.style.height = '46px';
        }, 0);
      }
    }, [value]);

    return (
      <Container className={className}>
        <Content>
          {title && (
            <TextElem
              tid={title}
              color="textPrimary"
              style={{
                height: '14px',
                display: 'block',
                marginBottom: '12px',
              }}
            />
          )}
          <Input
            name={name}
            onInput={handleInput}
            onChange={handleChange}
            onBlur={handleBlur}
            error={error}
            type={type}
            value={value}
            iconSize={iconSize}
            placeholder={placeholder && i18n.t(placeholder)}
            className={className}
            ref={ref}
            disabled={disabled}
            {...props}
          />
          <Icon ref={refIcon}>{icon}</Icon>
        </Content>
        {(error || errorMessage) && (
          <TextElem size="alert" color="error">
            {errorMessage}
          </TextElem>
        )}
      </Container>
    );
  },
  // },
);

const Input = styled.textarea<{
  error?: boolean;
  onIonChange?: Function;
  type?: string;
  iconSize: number;
}>`
  padding: ${Spacing(4)};
  resize: vertical;
  line-height: 150%;
  text-align: start;
  width: 100%;
  border: none;
  outline: none;
  height: 136px;
  transition: all 0.2s;

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }

  color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]};

  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  *:has(+ &) {
    transition: all 0.2s;
  }

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]};

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};

    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
    }
  }

  &:focus-within {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]} !important;
  }

  ::placeholder {
    line-height: 1.9;
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
  }

  &:focus-within {
    background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    border-color: ${({ theme }) => theme[COLOR_ENUM.ACTIVE]} !important;
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_ACTIVE]};
    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    }
    ::placeholder {
      display: none;
      color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    }
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme[COLOR_ENUM.ERROR]} !important;
    `}
`;

const FakeInput = styled.div`
  padding: ${Spacing(4)};
  height: 50px;
  font-size: ${({ theme }) => theme[SIZE_FONT_ENUM.INPUT]}px;
  color: ${({ theme }) => theme[COLOR_ENUM.DISABLED]};
  line-height: 1em;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.DISABLED]};
`;

const Content = styled.div`
  textarea {
    transition: all 0.2s;
  }
  span {
    transition: all 0.2s;
  }

  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};
`;

const Icon = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: max-content;
  box-sizing: content-box;
  z-index: 2;
`;
