import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { FieldTextElem } from '../../../common/field-text';

export const ReservationForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue }) => {
  return (
    <GridElem spacing={5}>
      <FieldTextElem
        name={FORM_VALUE_ENUM.BUTTON_TEXT}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.BUTTON_TEXT"
        value={getFieldValue(FORM_VALUE_ENUM.BUTTON_TEXT)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
        error={isFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
      />

      <DoubleContainerElem>
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.START_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME_BOOST}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TIME_BOOST"
          value={getFieldValue(FORM_VALUE_ENUM.TIME_BOOST)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
          error={isFieldError(FORM_VALUE_ENUM.TIME_BOOST)}
        />
      </DoubleContainerElem>
      <DoubleContainerElem>
        <FieldTextElem
          type="customNumber"
          name={FORM_VALUE_ENUM.PLACE_AVAILABLE}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.PLACE_AVAILABLE"
          value={getFieldValue(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
          error={isFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
        />
        <FieldTextElem
          type="customNumber"
          name={FORM_VALUE_ENUM.PLACE_RESERVED}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.PLACE_RESERVED"
          value={getFieldValue(FORM_VALUE_ENUM.PLACE_RESERVED)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PLACE_RESERVED)}
          error={isFieldError(FORM_VALUE_ENUM.PLACE_RESERVED)}
        />
      </DoubleContainerElem>
    </GridElem>
  );
};
