import React, { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { GridElem } from '../../common/grid';

import { View } from './view';
import logoIcon from '../../asset/svg/logo.svg';
import logoIconLight from '../../asset/svg/logo-light.svg';

import { Spacing } from '../../theme';
import { THEME_ENUM } from '../../data/theme/constant';
import adminIcon from '../../asset/svg/menu-app/admin.svg';
import { ReactComponent as OpenIcon } from '../../asset/svg/menu-app/open.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import webinarIcon from '../../asset/svg/menu-app/webinar.svg';
import analyticIcon from '../../asset/svg/menu-app/analytic.svg';
import settingIcon from '../../asset/svg/menu-app/setting.svg';
import adminActiveIcon from '../../asset/svg/menu-app/admin-active.svg';
import webinarActiveIcon from '../../asset/svg/menu-app/webinar-active.svg';
import analyticActiveIcon from '../../asset/svg/menu-app/analytic-active.svg';
import settingActiveIcon from '../../asset/svg/menu-app/setting-active.svg';
import { SETTINGS_PAGE_PATH } from '../../page/settings';
import { AUTH_MODULE_NAME } from '../../data/auth';
import { useSelector } from '../../lib/store';
import { WEBINAR_PAGE_PATH } from '../../page/webinar';
import { DividerElem } from '../../common/divider';
import { COLOR_ENUM } from '../../theme/color';
import { AccountSettingsLogoutContainer } from '../account-settings-logout';
import { ThemeChangeContainer } from '../theme-change';
import { ADMIN_PROJECT_PAGE_PATH } from '../../page/admin-project';
import { ADMIN_FILE_PAGE_PATH } from '../../page/admin-file';
import { ADMIN_USER_PAGE_PATH } from '../../page/admin-user';
import ReactDOM from 'react-dom';
import { FlexElem } from '../../common/flex';
import { useOnClickOutside } from 'usehooks-ts';
import { USER_ROLE } from '../../data/user/constant';
import { ANALYTICS_PAGE_PATH } from '../../page/analytics';

export const Component: React.FC<{
  isActivePath: Function;
  theme: THEME_ENUM;
  pathname: any;
}> = ({ isActivePath, theme }) => {
  const { state }: any = useSelector((s) => ({
    state: s[AUTH_MODULE_NAME],
  }));

  const [open, setOpen] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setOpen(false);
  };
  useOnClickOutside(ref, handleClickOutside);

  return (
    <Container ref={ref}>
      {ReactDOM.createPortal(
        <MobileHead onClick={() => setOpen(true)}>
          <OpenIconStyled />
          <img
            src={theme === THEME_ENUM.LIGHT ? logoIconLight : logoIcon}
            style={{ height: '16px' }}
          />
        </MobileHead>,
        document.getElementById('layoutElem') || document.body,
      )}

      <Content spacing={0} open={open}>
        <IconContainer>
          <LogoContainer spacing={12}>
            <img
              src={theme === THEME_ENUM.LIGHT ? logoIconLight : logoIcon}
              style={{ height: '18px' }}
            />
            <CloseIconStyled onClick={() => setOpen(false)} />
          </LogoContainer>
        </IconContainer>
        <DividerElem />

        <PageContainer spacing={0}>
          <GridElem spacing={1}>
            <View
              href={WEBINAR_PAGE_PATH}
              title="NAVIGATION.TAB_APP.WEBINAR"
              icon={webinarIcon}
              iconActive={webinarActiveIcon}
              isActive={isActivePath(WEBINAR_PAGE_PATH)}
            />
            {state?.user?.role === USER_ROLE.ADMIN && (
              <View
                href={ADMIN_USER_PAGE_PATH}
                title="NAVIGATION.TAB_APP.ADMIN"
                icon={adminIcon}
                iconActive={adminActiveIcon}
                isActive={isActivePath(
                  ADMIN_USER_PAGE_PATH,
                  ADMIN_FILE_PAGE_PATH,
                  ADMIN_PROJECT_PAGE_PATH,
                )}
              />
            )}
            <View
              href={ANALYTICS_PAGE_PATH}
              title="NAVIGATION.TAB_APP.ANALYTIC"
              icon={analyticIcon}
              iconActive={analyticActiveIcon}
              isActive={isActivePath('dds')}
            />
          </GridElem>
          <ThemeContainer>
            <ThemeChangeContainer />
          </ThemeContainer>
        </PageContainer>
        <DividerElem />
        <SettingsContainer spacing={0}>
          <View
            href={SETTINGS_PAGE_PATH}
            title="NAVIGATION.TAB_APP.SETTINGS"
            icon={settingIcon}
            iconActive={settingActiveIcon}
            isActive={isActivePath(SETTINGS_PAGE_PATH)}
          />
          <AccountSettingsLogoutContainer />
        </SettingsContainer>
      </Content>
    </Container>
  );
};

const LogoContainer = styled(FlexElem)`
  align-items: center;
  justify-content: space-between;
  @media screen and (width > 1200px) {
    justify-content: center;
  }
`;

const OpenIconStyled = styled(OpenIcon)`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const CloseIconStyled = styled(CloseIcon)`
  height: 24px;
  width: 24px;
  cursor: pointer;
  @media screen and (width > 1200px) {
    display: none;
  }
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
`;

const MobileHead = styled.div`
  @media screen and (width > 1200px) {
    display: none;
  }
  display: flex;
  justify-content: center;
  position: relative;
  height: 24px;
  align-items: center;
`;

const Content = styled(GridElem)<{ open: boolean }>`
  @media screen and (max-width: 1200px) {
    display: ${(props) => (props.open ? 'absolute' : 'none')};
    width: 60%;
    min-width: 270px;
  }
  width: 270px;

  border-right: 1px solid ${({ theme }) => theme[COLOR_ENUM.DIVIDER]};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};

  grid-template-rows: min-content 1px auto 1px min-content;
  height: 100dvh;
  position: fixed;
  z-index: 9999999;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;

  padding: ${Spacing(7)} ${Spacing(6)};

  @media screen and (width > 1200px) {
    padding: ${Spacing(12)} ${Spacing(6)} ${Spacing(9)};
  }
`;

const SettingsContainer = styled(GridElem)`
  padding: ${Spacing(7)} ${Spacing(5)} ${Spacing(6)};

  @media screen and (width > 1200px) {
    padding: ${Spacing(8)} ${Spacing(7)} ${Spacing(6)};
  }
`;

const ThemeContainer = styled.div`
  padding-left: ${Spacing(3)};
`;

const PageContainer = styled(GridElem)`
  padding: ${Spacing(7)} ${Spacing(5)} ${Spacing(6)};

  @media screen and (width > 1200px) {
    padding: ${Spacing(8)} ${Spacing(7)} ${Spacing(6)};
  }

  height: 100%;
  align-content: space-between;
`;

const Container = styled.div`
  grid-row: 1/3;
`;
