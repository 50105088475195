import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { PROJECT_ITEM_DATA } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { TextElem } from '../../../common/text';
import { ProjectItemDeleteContainer } from '../../../epic/project-item-delete';
import { ProjectItemUpdateContainer } from '../../../epic/project-item-update';
import { ReactComponent as EditIcon } from '../../../asset/svg/common/edit.svg';
import { ReactComponent as MoreIcon } from '../../../asset/svg/common/more.svg';
import { ContentContainerElem } from '../../../common/content-container';
import { COLOR_ENUM } from '../../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { DividerElem } from '../../../common/divider';
import { useRef } from 'react';

import { useOnClickOutside } from 'usehooks-ts';

import { ReactComponent as DeleteIcon } from '../../../asset/svg/delete/delete.svg';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

interface Props extends PROJECT_ITEM_DATA {
  updateVisible: string | null;
  setUpdateVisible: Function;
}

const Elem: React.FC<Props> = ({
  id,
  name,
  smsAlphaName,
  smsText,
  webinar,
  setUpdateVisible,
  updateVisible,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = () => {
    // Your custom logic here
    setModalVisible(false);
  };

  const closeModalVisible = () => setModalVisible(false);

  const deleteModalOpen = () => {
    setDeleteModalVisible(true);
    setTimeout(() => {
      closeModalVisible();
    });
  };
  const deleteModalClose = () => {
    setDeleteModalVisible(false);
  };

  const handleUpdateVisible = () => {
    setModalVisible(false);
    setUpdateVisible(id);
  };

  const handleCloseModal = () => {
    setUpdateVisible(null);
    setModalVisible(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const [height, setHeight] = useState(0);
  const updRef = useRef<any>(null);
  useEffect(() => {
    if (updRef.current) {
      setHeight(updRef.current?.offsetHeight);
    }
  }, [updateVisible]);
  return (
    <Card height={height} updVisible={updateVisible === id} hover={false}>
      {updateVisible === id && (
        <Div ref={updRef}>
          <ContentContainerElem>
            <ProjectItemUpdateContainer
              id={id}
              name={name}
              smsAlphaName={smsAlphaName}
              smsText={smsText}
              close={handleCloseModal}
            />
          </ContentContainerElem>
        </Div>
      )}
      <EllipsisContainerElem style={{ paddingRight: `${Spacing(5)}` }}>
        <TextElem
          tid="PROJECT.ITEM.NAME"
          tvalue={{ value: name }}
          type="semi-bold"
          size="main"
          oneLine
        />
      </EllipsisContainerElem>

      <RightContainer>
        <TextElem
          tid="PROJECT.ITEM.WEBINAR_COUNT"
          tvalue={{ value: webinar?.length }}
          color="textPrimary"
          oneLine
        />
        <ModalCntainer>
          <MoreIconStyled onClick={() => setModalVisible(true)} />

          {modalVisible && (
            <ModalElem ref={ref}>
              <ModalItem onClick={handleUpdateVisible}>
                <EditIcon />
                <TextElem tid="PROJECT.UPDATE.MODAL.EDIT" />
              </ModalItem>
              <DividerElem />
              <ModalItem onClick={deleteModalOpen}>
                <DeleteIcon />
                <TextElem tid="COMMON.MODAL.BUTTON.DELETE" color="error" />
              </ModalItem>
            </ModalElem>
          )}
        </ModalCntainer>

        <ProjectItemDeleteContainer
          projectId={id}
          open={deleteModalVisible}
          onClose={deleteModalClose}
        />
      </RightContainer>
    </Card>
  );
};

const Div = styled.div`
  width: calc(100% + 2px);
  position: absolute;
  z-index: 9999;
  top: -1px;
  left: -1px;
`;

const Card = styled(CardElem)<{ height?: number; updVisible: boolean }>`
  display: grid;
  cursor: auto;

  grid-column: span 2;
  grid-template-columns: subgrid;
  gap: 0;
  justify-content: space-between;
  position: relative;
  align-items: center;
  ${({ height, updVisible }) => {
    if (updVisible) {
      return css`
        :hover {
          border: solid 1px transparent;
          background-color: transparent;
        }
        background-color: transparent;
        height: ${height}px;
      `;
    }
  }}
`;

const MoreIconStyled = styled(MoreIcon)`
  transition: all 0.2s;
  circle {
    transition: all 0.2s;
    fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  }
  :hover {
    circle {
      fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_THIRD]};
    }
  }
`;
const ModalCntainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

const ModalItem = styled.div`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  align-items: center;
  span {
    height: 16px;
  }
  svg {
    path {
      transition: all 0.2s;
    }
  }
  :first-child {
    border-radius: 9px 9px 0 0;
  }

  :last-child {
    border-radius: 0 0 9px 9px;

    svg {
      path {
        stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
      }
    }
  }

  :hover {
    background: ${({ theme }) => theme[COLOR_ENUM.BORDER]};
    :last-child {
      svg {
        path {
          stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
        }
      }
    }
    svg {
      path {
        fill: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
      }
    }
  }
  /* border-bottom: 1px; */
`;
const ModalElem = styled.div`
  z-index: 999;
  background-color: ${({ theme }) => theme[COLOR_ENUM.INPUT_ACTIVE]};
  top: 30px;
  right: 0;
  width: 180px;
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  position: absolute;

  border-bottom: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};

  width: 180px;
  --height: auto;
  --box-shadow: none !important;
  --backdrop-opacity: 0;
`;

const RightContainer = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: center;
  max-width: 140px;
`;

export { Elem as ProjectItemElem };
