import React from 'react';
import { Pie, PieChart, Cell, Tooltip } from 'recharts';
import { TextElem } from '../text';
import styled from 'styled-components';
import { ContentContainerElem } from '../content-container';
import { THEME_ENUM } from '../../data/theme/constant';
import {
  COLOR_ENUM,
  DARK_COLOR_DATA,
  LIGHT_COLOR_DATA,
} from '../../theme/color';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { GridElem } from '../grid';
import { FlexElem } from '../flex';
import { EllipsisContainerElem } from '../ellipsis-container';
import { ReactComponent as EmptyIcon } from '../../asset/svg/analytic/empty.svg';

const COLORS = [
  '#B56BFF',
  '#68C1A8',
  '#F050D3',
  '#69CFFB',
  '#7A5AEF',
  '#48A489',
  '#F56DC9',
  '#73B7D9',
  '#A678FF',
  '#51B897',
  '#FF83E1',
  '#88D2F6',
  '#9B7AF5',
  '#54BEA5',
  '#FF9FEB',
];

export const Elem: React.FC<{
  data: { name: string; value: number }[];
}> = ({ data }) => {
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
  }: any) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={11}
        fontWeight={600}
      >
        {`${percent}%`}
      </text>
    );
  };

  const totalValue = data.reduce((acc, cur) => acc + cur.value, 0);

  const modifiedDataInfo: Array<{
    color: string;
    percent: string;
    name: string;
    value: number;
  }> = data.map((entry, index) => ({
    ...entry,
    color: COLORS[index % COLORS.length],
    percent: ((entry.value / totalValue) * 100).toFixed(0),
  }));

  const modifiedData: Array<{
    color: string;
    percent: string;
    name: string;
    value: number;
  }> = modifiedDataInfo
    .filter((entry) => entry.value !== 0)
    .map((entry, index) => ({
      ...entry,
    }));

  const { theme: themeType } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  const theme =
    themeType.type === THEME_ENUM.LIGHT ? LIGHT_COLOR_DATA : DARK_COLOR_DATA;

  console.log(modifiedData);

  return (
    <>
      {modifiedData.length ? (
        <Container>
          <PieChart width={120} height={120}>
            <Tooltip content={<></>} />

            <Pie
              data={modifiedData}
              dataKey="value"
              nameKey="name"
              cx="50%"
              cy="50%"
              outerRadius={60}
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {modifiedData.map((entry: any, index) => (
                <Cell
                  stroke={`${theme[COLOR_ENUM.BACKGROUND_PRIMARY]}`}
                  strokeWidth={modifiedData.length > 1 ? 2 : 0}
                  key={`cell-${index}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
          </PieChart>
          <FlexElem style={{ height: '100%' }}>
            <GridElem
              spacing={2}
              style={{
                alignItems: 'center',
                height: 'min-content',
                gridAutoColumns: '12px auto min-content min-content',
              }}
            >
              {modifiedDataInfo.map((item: any, index) => (
                <ItemContainer spacing={1}>
                  <Dot style={{ background: `${item.color}` }} />
                  <EllipsisContainerElem>
                    <TextElem
                      type="medium"
                      color="textSecondary"
                      oneLine
                      tid={item.name}
                      title={item.name}
                    />
                  </EllipsisContainerElem>

                  <div style={{ textAlign: 'end', marginLeft: '8px' }}>
                    <TextElem type="semi-bold" color="textSecondary">
                      {item.value}
                    </TextElem>
                  </div>
                  <div>
                    <TextElem
                      type="semi-bold"
                      color="textThird"
                    >{`${item.percent}%`}</TextElem>
                  </div>
                </ItemContainer>
              ))}
            </GridElem>
          </FlexElem>
        </Container>
      ) : (
        <ContainerEmpty>
          <GridElem spacing={5} style={{ justifyContent: 'center' }}>
            <EmptyIcon style={{ justifySelf: 'center' }} />
            <TextElem tid="Нажаль, поки немає ніяких даних" />
          </GridElem>
        </ContainerEmpty>
      )}
    </>
  );
};
const ContainerEmpty = styled(ContentContainerElem)`
  padding: 16px;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  height: 100%;
  align-items: center;
`;

const Container = styled(ContainerEmpty)`
  grid-template-columns: 120px auto;
  @media screen and (width<500px) {
    grid-template-columns: 1fr;
  }
`;

const Dot = styled.div`
  width: 12px;
  height: 12px;
  min-width: 12px;
  min-height: 12px;
  border-radius: 100%;
`;

const ItemContainer = styled(GridElem)`
  display: grid;
  grid-column: span 4;
  grid-template-columns: subgrid;
  justify-content: center;
  align-items: center;
`;
