import styled from 'styled-components';
import { GridElem } from '../../../common/grid';
import { SelectDynamicContainer } from '../../select-dynamic';
import { FormikValues } from 'formik';
import { FORM_VALUE_ENUM } from '../constant';
import { ContentContainerElem } from '../../../common/content-container';
import { Spacing } from '../../../theme';
import { FieldDataTimeRangeElem } from '../../../common/field-datetime-range';
import { ButtonElem } from '../../../common/button';
import { convertSessionListToSelect } from '../../../data/session/convert';
import { getByWebinarSessionList } from '../../../data/session/action';
import { convertWebinarListToSelect } from '../../../data/webinar/convert';
import UploadIcon from '../../../asset/svg/analytic/upload.svg';
import CloseIcon from '../../../asset/svg/analytic/close.svg';
import { getProjectList } from '../../../data/project/action';
import { convertProjectListToSelect } from '../../../data/project/convert';
import { getWebinarList } from '../../../data/webinar/action';

export const AnalyticFilterContainer: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  onRemove: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue, onRemove }) => {
  const getWebinarParam = (project: string[]) => {
    return `${project.map((item: any) => `&project=${item}`).join('')}`;
  };

  const getSessionParam = (webinar: string[]) => {
    return `${webinar.map((item: any) => `&webinar=${item}`).join('')}`;
  };
  // const setRange = (value: string) => {
  //   formik.setFieldValue(FORM_VALUE_ENUM.RANGE, value);
  // };

  return (
    <>
      <FiltertContainer spacing={3}>
        <SelectDynamic
          formik={formik}
          value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
          error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
          action={getProjectList}
          convert={convertProjectListToSelect}
          // dynamic
          name={FORM_VALUE_ENUM.PROJECT}
          isMulti
        />
        <SelectDynamic
          formik={formik}
          value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
          error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
          name={FORM_VALUE_ENUM.WEBINAR}
          action={getWebinarList}
          convert={convertWebinarListToSelect}
          isMulti
          param={getWebinarParam(getFieldValue(FORM_VALUE_ENUM.PROJECT))}
        />
        <SelectDynamic
          formik={formik}
          value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
          error={isFieldError(FORM_VALUE_ENUM.SESSION)}
          name={FORM_VALUE_ENUM.SESSION}
          action={getByWebinarSessionList}
          convert={convertSessionListToSelect}
          isMulti
          param={getSessionParam(getFieldValue(FORM_VALUE_ENUM.WEBINAR))}
        />
        <FieldDataTimeRangeElem
          showTime={false}
          onChange={formik.handleChange}
          name={FORM_VALUE_ENUM.RANGE}
        />
        <ButtonElem
          onClick={formik.handleSubmit}
          iconLeft={UploadIcon}
          iconSize={20}
          style={{ padding: 0 }}
          color="backgroundThird"
        />
        <ButtonElem
          color="error"
          // fill="icon"
          style={{ padding: 0 }}
          iconLeft={CloseIcon}
          iconSize={20}
          onClick={onRemove}
        />
      </FiltertContainer>
      <FiltertContainerMobile spacing={3}>
        <GridElem style={{ gridTemplateColumns: '1fr 1fr', gap: '8px' }}>
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.PROJECT)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PROJECT)}
            error={isFieldError(FORM_VALUE_ENUM.PROJECT)}
            action={getProjectList}
            convert={convertProjectListToSelect}
            dynamic
            name={FORM_VALUE_ENUM.PROJECT}
            isMulti
          />
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.WEBINAR)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.WEBINAR)}
            error={isFieldError(FORM_VALUE_ENUM.WEBINAR)}
            name={FORM_VALUE_ENUM.WEBINAR}
            action={getWebinarList}
            convert={convertWebinarListToSelect}
            isMulti
            dynamic
            param={getWebinarParam(getFieldValue(FORM_VALUE_ENUM.PROJECT))}
          />
        </GridElem>
        <Grid>
          <SelectDynamicContainer
            formik={formik}
            value={getFieldValue(FORM_VALUE_ENUM.SESSION)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.SESSION)}
            error={isFieldError(FORM_VALUE_ENUM.SESSION)}
            name={FORM_VALUE_ENUM.SESSION}
            action={getByWebinarSessionList}
            convert={convertSessionListToSelect}
            isMulti
            dynamic
            param={getSessionParam(getFieldValue(FORM_VALUE_ENUM.WEBINAR))}
          />
          <ButtonElem
            onClick={formik.handleSubmit}
            iconLeft={UploadIcon}
            iconSize={20}
            style={{ padding: 0 }}
            color="backgroundThird"
          />
          <ButtonElem
            color="error"
            style={{ padding: 0 }}
            iconLeft={CloseIcon}
            iconSize={20}
            onClick={onRemove}
          />
        </Grid>

        <FieldDataTimeRangeElem
          showTime={false}
          onChange={formik.handleChange}
          name={FORM_VALUE_ENUM.RANGE}
        />
      </FiltertContainerMobile>
    </>
  );
};

const SelectDynamic = styled(SelectDynamicContainer)`
  div:first-child {
    max-width: 180px;
  }
`;

const FiltertContainerMobile = styled(ContentContainerElem)`
  padding: ${Spacing(4)};
  display: grid;
  @media screen and (width>900px) {
    display: none;
  }
`;

const Grid = styled(GridElem)`
  display: grid;
  gap: ${Spacing(2)};
  grid-template-columns: auto 46px 46px;
`;

const FiltertContainer = styled(ContentContainerElem)`
  padding: ${Spacing(5)};
  display: none;
  @media screen and (width>900px) {
    display: grid;
  }
  grid-template-columns: 180px 180px 180px auto 46px 46px;
  div:nth-child(-n + 3) > div {
    /* max-width: 150px; */
  }
`;
