import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { getPersonList } from '../../../data/person/action';
import { convertPersonListToSelect } from '../../../data/person/convert';
import { SelectDynamicContainer } from '../../select-dynamic';

export const ReservationCreatedForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  sessionId: string;
  setFieldValue: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  sessionId,
}) => {
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <SelectDynamicContainer
          name={FORM_VALUE_ENUM.PERSON}
          action={getPersonList}
          convert={convertPersonListToSelect}
          formik={formik}
          title="EVENT.FORM.PERSON"
          errorMessage={getFieldError(FORM_VALUE_ENUM.PERSON)}
          error={isFieldError(FORM_VALUE_ENUM.PERSON)}
          value={getFieldValue(FORM_VALUE_ENUM.PERSON)}
          param={sessionId}
        />

        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TIME"
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
      </DoubleContainerElem>
    </GridElem>
  );
};
