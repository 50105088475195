import React, { useState } from 'react';

import { FormikValues } from 'formik';

import { FORM_VALUE_ENUM } from './constant';

import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { i18n } from '../../lib/lang';

import { TextElem } from '../../common/text';
import { DoubleContainerElem } from '../../common/double-container';
import styled from 'styled-components';
import { Spacing } from '../../theme';
import { ContentContainerElem } from '../../common/content-container';
import addIcon from '../../asset/svg/button/plus.svg';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';

import { ReactComponent as AddIconLarge } from '../../asset/svg/common/add.svg';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { FieldTextAreaElem } from '../../common/field-text-area';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  formVisible: boolean;
  setFormVisible: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  formVisible,
  setFormVisible,
}) => {
  const closeModal = () => {
    formik.resetForm();

    setFormVisible(false);
  };
  return (
    <>
      {formVisible ? (
        <FormElem onSubmit={formik.handleSubmit}>
          {isLoading && <LoaderElem />}
          <ContentContainerElem>
            <GridElem spacing={5}>
              <TitleContainer>
                <TextElem
                  tid="PROJECT.CREATE.TITLE"
                  type="semi-bold"
                  size="main"
                />
                <CloseIcon onClick={closeModal} style={{ cursor: 'pointer' }} />
              </TitleContainer>
              <DoubleContainerElem>
                <FieldTextElem
                  name={FORM_VALUE_ENUM.NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="PROJECT.CREATE.FORM.NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.NAME)}
                />

                <FieldTextElem
                  name={FORM_VALUE_ENUM.SMS_ALPHA_NAME}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="PROJECT.FORM.SMS_ALPHA_NAME"
                  value={getFieldValue(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
                  error={isFieldError(FORM_VALUE_ENUM.SMS_ALPHA_NAME)}
                />
              </DoubleContainerElem>
              <FieldTextAreaElem
                name={FORM_VALUE_ENUM.SMS_TEXT}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                title="PROJECT.FORM.SMS_TEXT"
                value={getFieldValue(FORM_VALUE_ENUM.SMS_TEXT)}
                errorMessage={getFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
                error={isFieldError(FORM_VALUE_ENUM.SMS_TEXT)}
              />
              <DoubleContainerElem>
                <ButtonStyled
                  type="submit"
                  tid="PROJECT.CREATE.BUTTON"
                  fill="solid"
                  disabled={isSubmitDisabled()}
                  iconRight={addIcon}
                  onClick={formik.handleSubmit}
                />
              </DoubleContainerElem>

              {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            </GridElem>
          </ContentContainerElem>
        </FormElem>
      ) : (
        <AddContainer onClick={() => setFormVisible(true)}>
          <TextElem tid="PROJECT.CREATE.ADD" type="semi-bold" size="main" />
          <AddIconLarge />
        </AddContainer>
      )}
    </>
  );
};

const ButtonStyled = styled(ButtonElem)`
  margin-top: 26px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AddContainer = styled(TitleContainer)`
  padding: ${Spacing(4)} ${Spacing(5)};
  cursor: pointer;
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CARD]}px;
  transition: all 0.2s;
  :hover {
    background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
`;
