import React, { useEffect } from 'react';
import { useMutation } from 'react-query';
import { Component } from './component';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
} from './constant';
import { useFormik, FormikValues } from 'formik';
import { validation } from '../../lib/validation';
import { required } from '../../lib/validation/service';
import { AxiosResponse } from 'axios';
import {
  VIEWER_ITEM_DATA,
  VIEWER_ITEM_DATA_RAW,
} from '../../data/viewer/constant';
import { action } from './action';

const config = {
  [FORM_VALUE_ENUM.PROJECT]: [],
  [FORM_VALUE_ENUM.WEBINAR]: [],
  [FORM_VALUE_ENUM.SESSION]: [],
  [FORM_VALUE_ENUM.RANGE]: [],
};

export const Container: React.FC<{
  projectId?: string;
  webinarId?: string;
  sessionId?: string;
  onRemove: Function;
}> = ({ projectId, webinarId, sessionId, onRemove }) => {
  const initialValues = {
    [FORM_VALUE_ENUM.PROJECT]: projectId ? [projectId] : [],
    [FORM_VALUE_ENUM.WEBINAR]: webinarId ? [webinarId] : [],
    [FORM_VALUE_ENUM.SESSION]: sessionId ? [sessionId] : [],
    [FORM_VALUE_ENUM.RANGE]: '',
  };

  const formik: FormikValues = useFormik({
    initialValues,
    validate: (values: FormikValues) => validation(values, config),
    onSubmit: (values: FORM_VALUE_INTER) => {
      // Вызываем мутацию при сабмите формы
      submitViewerAnalytics(values);
    },
  });

  const {
    mutate: submitViewerAnalytics,
    data,
    isLoading,
    isSuccess,
    isError,
  } = useMutation<
    AxiosResponse<VIEWER_ITEM_DATA_RAW[]>,
    ACTION_ERROR_INTER,
    FORM_VALUE_INTER
  >((values: FORM_VALUE_INTER) => action(values), {
    onSuccess: (data) => {},
    onError: (error) => {},
  });

  useEffect(() => {
    if (projectId || webinarId || sessionId) {
      submitViewerAnalytics(formik.initialValues);
    }
  }, [projectId, webinarId, sessionId, submitViewerAnalytics]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];
  console.log(data);

  const getData = (): VIEWER_ITEM_DATA[] | undefined => {
    const readyData = data as unknown as VIEWER_ITEM_DATA[];

    if (readyData) {
      return readyData;
    }
  };

  return (
    <Component
      isLoading={isLoading}
      isSuccess={isSuccess}
      data={getData()}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      getFieldValue={getFieldValue}
      formik={formik}
      onRemove={onRemove}
    />
  );
};
