import React from 'react';

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';
import { FieldTextElem } from '../../../common/field-text';
import styled from 'styled-components';
import { FieldColorInputElem } from '../../../common/field-color-input';
import { TextElem } from '../../../common/text';

export const ButtonForm: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
}> = ({ formik, isFieldError, getFieldError, getFieldValue }) => {
  return (
    <GridElem spacing={5}>
      <FirstFormRow>
        <FieldTextElem
          name={FORM_VALUE_ENUM.BUTTON_TEXT}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          title="EVENT.FORM.BUTTON_TEXT"
          value={getFieldValue(FORM_VALUE_ENUM.BUTTON_TEXT)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
          error={isFieldError(FORM_VALUE_ENUM.BUTTON_TEXT)}
        />
        <DoubleContainerElem>
          <FieldTimeInputElem
            name={FORM_VALUE_ENUM.TIME}
            onChange={formik.handleChange}
            onBlur={formik.setFieldTouched}
            title="EVENT.FORM.START_TIME"
            value={getFieldValue(FORM_VALUE_ENUM.TIME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
            error={isFieldError(FORM_VALUE_ENUM.TIME)}
          />
          <FieldTimeInputElem
            name={FORM_VALUE_ENUM.END_TIME}
            onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            title="EVENT.FORM.END_TIME"
            value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
            error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
          />
        </DoubleContainerElem>
      </FirstFormRow>
      <FieldTextElem
        name={FORM_VALUE_ENUM.LINK}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        title="EVENT.FORM.LINK"
        value={getFieldValue(FORM_VALUE_ENUM.LINK)}
        errorMessage={getFieldError(FORM_VALUE_ENUM.LINK)}
        error={isFieldError(FORM_VALUE_ENUM.PLACE_AVAILABLE)}
      />
      <LastFormRow>
        <FieldColorInputElem
          name={FORM_VALUE_ENUM.BUTTON_COLOR}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.BUTTON_COLOR"
          value={getFieldValue(FORM_VALUE_ENUM.BUTTON_COLOR)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.BUTTON_COLOR)}
          error={isFieldError(FORM_VALUE_ENUM.BUTTON_COLOR)}
        />
        <FieldColorInputElem
          name={FORM_VALUE_ENUM.TEXT_COLOR}
          onChange={formik.handleChange}
          // onBlur={formik.handleBlur}
          title="EVENT.FORM.TEXT_COLOR"
          value={getFieldValue(FORM_VALUE_ENUM.TEXT_COLOR)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TEXT_COLOR)}
          error={isFieldError(FORM_VALUE_ENUM.TEXT_COLOR)}
        />
        <GridElem spacing={2}>
          <TextElem color="textPrimary" tid="EVENT.FORM.PREVIEW" />
          <Button
            textColor={getFieldValue(FORM_VALUE_ENUM.TEXT_COLOR)}
            color={getFieldValue(FORM_VALUE_ENUM.BUTTON_COLOR)}
          >
            <TextElem
              tid={getFieldValue(FORM_VALUE_ENUM.BUTTON_TEXT)}
              type={'semi-bold'}
            />
          </Button>
        </GridElem>
      </LastFormRow>
    </GridElem>
  );
};

const LastFormRow = styled.div`
  display: grid;
  gap: 10px;
  @media (width< 700px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: repeat(6, 1fr);

    > :nth-child(1) {
      grid-column: 1/4;
    }
    > :nth-child(2) {
      grid-column: 4/7;
    }
    > :nth-child(3) {
      grid-column: 1/7;
    }
  }
  @media (width< 500px) {
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr;
    > :nth-child(1) {
      grid-column: 1;
    }
    > :nth-child(2) {
      grid-column: 1;
    }
    > :nth-child(3) {
      grid-column: 1;
    }
  }

  grid-template-columns: repeat(3, 1fr);
`;

const FirstFormRow = styled(DoubleContainerElem)`
  @media screen and (width<740px) {
    grid-template-columns: 1fr;
  }
`;

export const Button = styled.div<{ color: string; textColor: string }>`
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;

  background: ${({ color }) => color};
  & span {
    color: ${({ textColor }) => textColor} !important;
  }
`;
