import React from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import { LayoutAppElem } from '../../common/layout-app';
import { PageElem } from '../../common/page';
import queryString from 'query-string';
import { AnalyticListContainer } from '../../epic/analytic-list';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { MenuAppContainer } from '../../epic/menu-app';
import { LayoutElem } from '../../common/layout';
import { THEME_ENUM } from '../../data/theme/constant';
import { HeaderContainer } from '../../epic/header';
import icon from '../../asset/svg/header/analytic.svg';
import iconLight from '../../asset/svg/header/analytic-light.svg';
import styled from 'styled-components';

export const Page: React.FC<RouteComponentProps> = () => {
  const location = useLocation();
  const query = queryString.parse(location.search);
  const projectId = query.projectId as string;
  const webinarId = query.webinarId as string;
  const sessionId = query.sessionId as string;

  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElemStyled size="default">
          <HeaderContainer
            icon={theme.type === THEME_ENUM.LIGHT ? iconLight : icon}
            title="WEBINAR.LIST.HEADER"
            size="default"
          />
          <AnalyticListContainer
            projectId={projectId}
            webinarId={webinarId}
            sessionId={sessionId}
          />
        </LayoutElemStyled>
      </LayoutAppElem>
    </PageElem>
  );
};

const LayoutElemStyled = styled(LayoutElem)`
  padding-right: 0;
  height: 100%;
`;
