import React, { useEffect, useState } from 'react';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';

import { Component } from './component';
import {
  maxLength,
  name,
  phone,
  required,
  userName,
} from '../../lib/validation/service';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { useMutation, useQuery } from 'react-query';
import { action as fetch } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { VIEWER_ITEM_DATA } from '../../data/viewer/constant';
import { appLoadLocalStateData, appSaveLocalStateData } from '../../data/app';
import { useDispatch } from '../../lib/store';
import { setSessionTokenToLocal } from '../../data/auth/action';
import { VIEWER_ITEM_DATA_MODULE_NAME } from '../viewer-item-data';
import { HttpRequest } from '../../lib/http';

export const Container: React.FC<{ sessionId: string }> = ({ sessionId }) => {
  const preFetch = useQuery([VIEWER_ITEM_DATA_MODULE_NAME], {
    enabled: !!HttpRequest.defaults.headers.common['session_token'],
  });

  const [modalVisible, setModalVisible] = useState(false);
  const [reactionActive, setReactionActive] = useState<boolean>(true);

  const dispatch = useDispatch();

  const modalClose = () => {
    setModalVisible(false);
  };

  const onSuccess = (d: any) => {
    dispatch(setSessionTokenToLocal(d.sessionId, d.accessToken)).then(() => {
      preFetch.refetch();
    });
    modalClose();
  };

  const action = useMutation(
    (values: FORM_VALUE_INTER) => fetch(values, sessionId),
    { onSuccess },
  );

  const config = {
    [FORM_VALUE_ENUM.NAME]: [required, userName, maxLength(80)],
    [FORM_VALUE_ENUM.PHONE]: [required, phone],
    [FORM_VALUE_ENUM.PHOTO]: [],
    [FORM_VALUE_ENUM.ANIMATION]: [],
  };

  const validate = (values: FormikValues) => validation(values, config);

  const getData = (): VIEWER_ITEM_DATA | undefined => {
    const data = preFetch.data as unknown as VIEWER_ITEM_DATA;
    if (data) {
      return data;
    }
  };

  const isSuccessFecth = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const data = getData();
  const initialValues =
    isSuccessFecth() && data
      ? {
          [FORM_VALUE_ENUM.NAME]: data?.name,
          [FORM_VALUE_ENUM.PHONE]: data?.phone,
          [FORM_VALUE_ENUM.PHOTO]: data?.photo?.id,
          [FORM_VALUE_ENUM.ANIMATION]: null,
        }
      : {
          [FORM_VALUE_ENUM.NAME]: '',
          [FORM_VALUE_ENUM.PHONE]: '',
          [FORM_VALUE_ENUM.PHOTO]: '',
          [FORM_VALUE_ENUM.ANIMATION]: null,
        };

  const formik: FormikValues = useFormik({
    initialValues,
    validate,
    enableReinitialize: true,
    onSubmit: (values: FORM_VALUE_INTER) => {
      return action.mutate(values);
    },
  });

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (action.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (action.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    if (error) {
      return error.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: any) =>
    formik.setFieldValue(name, e.value);

  const onSuccessUpload = (id: string, name: string) => {
    formik.setFieldValue(name, id);
  };
  const [viewed, setViewed] = useState(true);

  const modalOpen = async () => {
    const localStoreData = await appLoadLocalStateData(MODULE_NAME);

    if (data) {
      let newValue: string[];
      if (
        localStoreData &&
        localStoreData[sessionId] &&
        !localStoreData[sessionId].includes(data.id)
      ) {
        newValue = [...localStoreData[sessionId], data.id];
      } else {
        newValue = [data.id];
      }
      if (newValue) {
        await appSaveLocalStateData(MODULE_NAME, {
          ...localStoreData,
          [sessionId]: newValue,
        });
      }
    }
    setViewed(true);
    setModalVisible(true);
  };

  useEffect(() => {
    appLoadLocalStateData(MODULE_NAME).then((localData) => {
      if (data && localData) {
        if (localData[sessionId] && localData[sessionId]?.includes(data.id)) {
          setViewed(true);
        }
        if (localData.reactionActive === false) {
          setReactionActive(false);
        }
      }
    });
  }, [data]);

  const changeReaction = async () => {
    const localStoreData = await appLoadLocalStateData(MODULE_NAME);

    if (localStoreData) {
      await appSaveLocalStateData(MODULE_NAME, {
        ...localStoreData,
        reactionActive: !reactionActive,
      });
    }
    setReactionActive(!reactionActive);

    setFieldValue(FORM_VALUE_ENUM.ANIMATION, reactionActive);
  };
  return (
    <Component
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      isError={isError()}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      errorMessage={getErrorMessage()}
      setFieldValue={setFieldValue}
      onSuccessUpload={onSuccessUpload}
      modalOpen={modalOpen}
      modalClose={modalClose}
      modalVisible={modalVisible}
      viewed={viewed}
      data={data}
      changeReaction={changeReaction}
      reactionActive={reactionActive}
    />
  );
};
