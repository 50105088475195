import React, { useEffect, useMemo, useState } from 'react';

import { GridElem } from '../../common/grid';

import { TextElem } from '../../common/text';

import styled from 'styled-components';
import { COLOR_ENUM } from '../../theme/color';
import { Spacing } from '../../theme';
import { ReactComponent as ArrowIcon } from '../../asset/svg/common/downArrow.svg';
import { ModalElem } from '../../common/modal';
import { StepperElem } from '../../common/stepper';
import { SESSION_ITEM_DATA } from '../../data/session/constant';
import { IonSkeletonText } from '@ionic/react';
import { SessionItemRoomGetRecordContainer } from '../session-item-room-get-record';
import { WEBGIFT_ITEM_DATA_RAW } from '../../data/webgift/constant';
import { FlexElem } from '../../common/flex';
import { STEPPER_ITEM } from '../../common/stepper/elem';
import { AvatarElem } from '../../common/avatar';

export const Component: React.FC<{
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  data?: SESSION_ITEM_DATA;
  modalDefault: boolean;
  withoutButtons: boolean;
  setModalVisibleDefault?: Function;
  stepperList?: STEPPER_ITEM[];
}> = ({
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  data,
  modalDefault,
  withoutButtons,
  setModalVisibleDefault,
  stepperList,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const modalOpen = () => {
    if (setModalVisibleDefault) {
      setModalVisibleDefault(true);
    } else {
      setModalVisible(true);
    }
  };

  const modalClose = () => {
    if (setModalVisibleDefault) {
      setModalVisibleDefault(false);
    } else {
      setModalVisible(false);
    }
  };

  useEffect(() => {
    let activeStepIndex = -1;

    const interval = setInterval(() => {
      if (stepperList && data?.serverDate) {
        const now = new Date();
        const timeDifference =
          (now.getTime() - new Date(data?.serverDate).getTime()) / 1000; // в секундах

        // Пропускаем элементы, которые уже были активны
        for (let i = activeStepIndex + 1; i < stepperList.length; i++) {
          const item = stepperList[i];
          if (item?.count) {
            const [hours, minutes, seconds] = item?.count
              ?.split(':')
              .map(Number);
            const itemTimeInSeconds = hours * 3600 + minutes * 60 + seconds;

            if (itemTimeInSeconds <= timeDifference) {
              activeStepIndex = i;
              setActiveStep(i + 1);
            } else {
              break;
            }
          }
        }
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [stepperList, data?.serverDate]);

  return (
    <>
      {isSuccess && data && stepperList && (
        <>
          {!withoutButtons && (
            <Button onClick={modalOpen}>
              <TextElem type="semi-bold" size="medium" oneLine>
                {data.name}
              </TextElem>
              <ArrowIcon />
            </Button>
          )}

          <Modal
            open={setModalVisibleDefault ? modalDefault : modalVisible}
            onClose={modalClose}
            titleTid="SESSION.VIEWER.SCENARIO.TITLE"
            closeIcon
          >
            <GridElem spacing={5}>
              <SpeakerInfo>
                <AvatarElem
                  userAvatar={data?.webinar?.speakerImg?.url}
                  size={28}
                />
                <GridElem spacing={1}>
                  <TextElem tid="SESSION.ITEM.SPEAKER" size="verySmall" />
                  <TextElem
                    tid="SESSION.ITEM.SPEAKER_NAME"
                    type="semi-bold"
                    size="semiSmall"
                    tvalue={{
                      value: data?.webinar?.speakerName,
                    }}
                  />
                </GridElem>
              </SpeakerInfo>
              {(!!data.webinar.webgift?.length || !!stepperList?.length) && (
                <GridElem spacing={8}>
                  {!!stepperList?.length && (
                    <StepperElem
                      stepArray={stepperList}
                      activeStep={activeStep}
                    />
                  )}
                  {!!data.webinar.webgift?.length && (
                    <GridElem spacing={4}>
                      <TextElem
                        tid="SESSION.VIEWER.SCENARIO.GIFT"
                        size="title"
                        type="semi-bold"
                        style={{ margin: 'auto' }}
                      />

                      <GridElem spacing={3}>
                        {data.webinar.webgift.map(
                          (item: WEBGIFT_ITEM_DATA_RAW) => (
                            <GiftCard spacing={4}>
                              <ImageContainer>
                                <Image src={item.photo?.url} />
                              </ImageContainer>
                              <GridElem
                                style={{
                                  justifyContent: 'start',
                                  textAlign: 'start',
                                }}
                                spacing={2}
                              >
                                <TextElem type="semi-bold" size="main">
                                  {item.name}
                                </TextElem>
                                <TextElem
                                  type="medium"
                                  size="mobileDefault"
                                  color="textPrimary"
                                >
                                  {item.description}
                                </TextElem>
                              </GridElem>
                            </GiftCard>
                          ),
                        )}
                      </GridElem>
                    </GridElem>
                  )}
                </GridElem>
              )}

              <SessionItemRoomGetRecordContainer />
            </GridElem>
          </Modal>
        </>
      )}
      {isLoading && (
        <Button>
          <IonSkeletonText animated style={{ height: '26px' }} />
          <ArrowIcon />
        </Button>
      )}
    </>
  );
};

const SpeakerInfo = styled.div`
  display: flex;
  gap: ${Spacing(3)};
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
`;

const ImageContainer = styled(FlexElem)`
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;

const GiftCard = styled(GridElem)`
  grid-template-columns: 85px auto;
  /* grid-template-rows: 85px; */

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  padding: ${Spacing(3)};
  border-radius: 12px;
  span {
    text-align: start;
  }
`;

const Modal = styled(ModalElem)`
  ::part(content) {
    width: 472px;
    @media screen and (width<700px) {
      width: 472px;
      top: 100px;
      bottom: auto;
      left: auto;
    }
    @media screen and (width<472px) {
      width: 100%;
      top: auto;
      bottom: 0;
      left: 0;
    }
    overflow: auto;
  }
  #content-container {
    max-height: calc(70dvh - 81px);
    @media screen and (width<472px) {
      max-height: 80dvh;
    }
    scrollbar-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]}
      transparent;
    scrollbar-width: thin;
    overflow-y: auto;
  }
  .modalTitle {
    text-align: center;
  }
`;

const Button = styled(GridElem)`
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_SECONDARY]};
  border-radius: 20px;
  cursor: pointer;
  padding: ${Spacing(4)} ${Spacing(5)};
  grid-template-columns: auto 20px;
  gap: ${Spacing(4)};
  align-items: center;
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]};
    }
  }
  path {
    transition: all 0.2s;
  }
  @media screen and (width<600px) {
    span {
      font-size: 13px;
    }
    svg {
      width: 16px;
    }
  }
`;
