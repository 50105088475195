import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AlertActionElem } from '../../common/alert-action';
import { GridElem } from '../../common/grid';
import { TextElem } from '../../common/text';
import { COLOR_ENUM } from '../../theme/color';
import { WEBINAR_ITEM_DATA } from '../../data/webinar/constant';
import { DoubleContainerElem } from '../../common/double-container';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { Spacing } from '../../theme';

import addIcon from '../../asset/svg/button/plus-light.svg';
import addIconLight from '../../asset/svg/button/plus.svg';
import { ReactComponent as DeleteIcon } from '../../asset/svg/common/close.svg';
import { WEBINAR_UPDATE_FORM_VALUE_ENUM } from '../webinar-item-update-general';
import { FormikValues } from 'formik';
import { ReactComponent as ColapseIcon } from '../../asset/svg/common/colapse.svg';
import { CustomInput } from '../../common/field-text/elem';
import { Skeleton } from './skeleton';
import { name } from '../../lib/validation/service';
import { useSelector } from '../../lib/store';
import { THEME_MODULE_NAME } from '../../data/theme';
import { THEME_ENUM } from '../../data/theme/constant';

export const Component: React.FC<{
  formik: FormikValues;
  list: string[];
  data?: WEBINAR_ITEM_DATA;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
}> = ({ list, data, isLoading, isSuccess, isError, errorMessage, formik }) => {
  const [newList, setNewList] = useState(list);
  const [newGuarantes, setNewGuarantes] = useState('');
  const [newGuarantesVisible, setNewGuarantesVisible] = useState(false);

  const handleChange = (e: { target: { value: string } }) => {
    setNewGuarantes(e.target.value);
  };

  const onSubmit = (e: any) => {
    if (newGuarantes && !name(e.target.value)) {
      setNewList([...newList, newGuarantes]);
    }
    setNewGuarantes('');
    setNewGuarantesVisible(false);
  };

  useEffect(() => {
    formik.setFieldValue(WEBINAR_UPDATE_FORM_VALUE_ENUM.GUARANTES, newList);
    formik.handleSubmit();
  }, [JSON.stringify(newList)]);

  const handleDelete = (index: number) => {
    const List = [...newList];
    List.splice(index, 1);
    setNewList([...List]);
  };

  const handleEdit = (index: number, e: any) => {
    if (!name(e.target.value)) {
      const List = [...newList];
      List[index] = e.target.value;
      setNewList(List);
    }
  };

  const pressEnter = (e: any) => {
    if (!name(e.target.value)) {
      if (e.key === 'Enter') e.target.blur();
    }
  };

  const handleDragStart = (e: any, index: number) => {
    e.dataTransfer.setData('index', index);
  };

  const handleDragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any, newIndex: number) => {
    e.preventDefault();

    const oldIndex = e.dataTransfer.getData('index');
    const newItems = [...newList];
    const [draggedItem] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, draggedItem);

    setNewList(newItems);
  };

  useEffect(() => {
    setNewList(list);
  }, [list]);

  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  return (
    <GridElem spacing={2}>
      <TextElem
        tid="WEBINAR.UPDATE.DESCRIPTION.GUARANTE.TITLE"
        color="textPrimary"
      />

      {isLoading && <Skeleton />}

      {isError && <AlertActionElem text={errorMessage} />}

      {isSuccess && newList && (
        <GridElem spacing={3}>
          {newList.map((item: string, index: number) => (
            <CardContainer
              key={`${index}${item}`}
              draggable
              onDragStart={(e) => handleDragStart(e, index)}
              onDragOver={handleDragOver}
              onDrop={(e) => handleDrop(e, index)}
            >
              <Card>
                <Field
                  defaultValue={item}
                  onBlur={(e) => {
                    handleEdit(index, e);
                  }}
                  onKeyDown={pressEnter}
                />
                {name(item) && (
                  <TextElem size="alert" color="error">
                    {name(item)}
                  </TextElem>
                )}
                <DeleteIconStyled onClick={() => handleDelete(index)} />
              </Card>

              <ColapseIcon />
            </CardContainer>
          ))}
          {newGuarantesVisible && (
            <CardContainer>
              <FieldTextElem
                onChange={handleChange}
                onBlur={onSubmit}
                value={newGuarantes}
                autoFocus
                onKeyDown={pressEnter}
                errorMessage={name(newGuarantes)}
              />
            </CardContainer>
          )}
          <DoubleContainerElem>
            <ButtonElem
              type="submit"
              tid="WEBINAR.UPDATE.DESCRIPTION.GUARANTE.CREATE"
              fill="solid"
              color="backgroundThird"
              onClick={() => setNewGuarantesVisible(true)}
              // disabled={isSubmitDisabled()}
              iconRight={
                theme.type === THEME_ENUM.LIGHT ? addIconLight : addIcon
              }
            />
          </DoubleContainerElem>
          {/* {isError && <AlertActionElem text={i18n.t(`${errorMessage}`)} />}
            {isSuccess && <AlertActionElem type="success" tid="Success" />} */}
        </GridElem>
      )}
    </GridElem>
  );
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto 16px;
  align-items: center;
  gap: ${Spacing(3)};
  cursor: pointer;
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  top: 15px;
  right: 16px;
  position: absolute;
  path {
    transition: all 0.2s;
  }
  :hover {
    path {
      stroke: ${({ theme }) => theme[COLOR_ENUM.ERROR]};
    }
  }
`;

const Field = styled(CustomInput)``;

const Card = styled.div`
  position: relative;

  input {
    transition: all 0.2s;
    background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  }
  :hover {
    input {
      background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
      border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
    }
    input:focus-visible {
      background: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
    }
  }
`;
