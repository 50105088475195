import React from 'react';
import { FormikProvider } from 'formik';
import { FieldArray } from 'formik';
import { GridElem } from '../../../common/grid';
import { DoubleContainerElem } from '../../../common/double-container';
import { FORM_VALUE_ENUM } from '../constant';
import { FieldTimeInputElem } from '../../../common/field-time-input';

import styled from 'styled-components';
import { ButtonElem } from '../../../common/button';
import { FieldTextElem } from '../../../common/field-text';
import { ReactComponent as DeleteIcon } from '../../../asset/svg/common/close.svg';

import { SelectElem } from '../../../common/select';

import addIcon from '../../../asset/svg/button/plus-light.svg';
import addIconLight from '../../../asset/svg/button/plus.svg';
import { THEME_MODULE_NAME } from '../../../data/theme';
import { useSelector } from '../../../lib/store';
import { THEME_ENUM } from '../../../data/theme/constant';
import CloseIcon from '../../../asset/svg/common/close.svg';
import { Spacing } from '../../../theme';
import { EMOJI_OPTION_LIST } from '../../../data/event/constant';

export const ReactionForm: React.FC<{
  formik: any;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  setFieldValue: Function;
  getSelectValue: Function;
  isSubmitDisabled: Function;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  getFieldValue,
  setFieldValue,
  getSelectValue,
  isSubmitDisabled,
}) => {
  const { theme } = useSelector((s: any) => ({
    theme: s[THEME_MODULE_NAME],
  }));

  console.log(formik);
  return (
    <GridElem spacing={5}>
      <DoubleContainerElem>
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.TIME}
          onChange={formik.handleChange}
          title="EVENT.FORM.TIME"
          onBlur={formik.setFieldTouched}
          value={getFieldValue(FORM_VALUE_ENUM.TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.TIME)}
          error={isFieldError(FORM_VALUE_ENUM.TIME)}
        />
        <FieldTimeInputElem
          name={FORM_VALUE_ENUM.END_TIME}
          onChange={formik.handleChange}
          onBlur={formik.setFieldTouched}
          title="EVENT.FORM.END_TIME"
          value={getFieldValue(FORM_VALUE_ENUM.END_TIME)}
          errorMessage={getFieldError(FORM_VALUE_ENUM.END_TIME)}
          error={isFieldError(FORM_VALUE_ENUM.END_TIME)}
        />
      </DoubleContainerElem>

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <GridElem spacing={15}>
            <React.Fragment>
              <GridElem spacing={2}>
                <FieldArray
                  name="reaction"
                  render={(arrayHelpers) => (
                    <DoubleContainerElem>
                      <ReactionWrapper>
                        {formik.values.reaction.map(
                          (reaction: any, index: number) => {
                            const deleteReaction = () => {
                              if (formik.values.reaction.length > 1) {
                                arrayHelpers.remove(index);
                              }
                            };
                            return (
                              <ReactionControlContainer key={index}>
                                <ReactionFieldContainer>
                                  <SelectElem
                                    name={`${FORM_VALUE_ENUM.REACTION}[${index}].emoji`}
                                    title="EVENT.FORM.EMOJI"
                                    options={EMOJI_OPTION_LIST}
                                    onChange={setFieldValue}
                                    value={getSelectValue(
                                      EMOJI_OPTION_LIST,
                                      `${FORM_VALUE_ENUM.REACTION}[${index}].emoji`,
                                    )}
                                    onBlur={() =>
                                      formik.setFieldTouched(
                                        `${FORM_VALUE_ENUM.REACTION}[${index}].emoji`,
                                        true,
                                      )
                                    }
                                    errorMessage={
                                      formik.touched?.reaction?.[index]
                                        ?.emoji &&
                                      formik.errors?.reaction?.[index]?.emoji
                                    }
                                    error={
                                      formik.touched?.reaction?.[index]
                                        ?.emoji &&
                                      formik.errors?.reaction?.[index]?.emoji
                                    }
                                  />
                                  <FieldTextElem
                                    name={`${FORM_VALUE_ENUM.REACTION}.${index}.quantity`}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    title="EVENT.FORM.QUANTITY"
                                    type="customNumber"
                                    step="0.00001"
                                    min="0"
                                    onKeyDown={(evt) =>
                                      evt.key === ',' && evt.preventDefault()
                                    }
                                    value={
                                      formik.values.reaction[index].quantity
                                    }
                                    errorMessage={
                                      formik.touched?.reaction?.[index]
                                        ?.quantity &&
                                      formik.errors?.reaction?.[index]?.quantity
                                    }
                                    error={
                                      formik.touched?.reaction?.[index]
                                        ?.quantity &&
                                      formik.errors?.reaction?.[index]?.quantity
                                    }
                                  />
                                </ReactionFieldContainer>
                                <ButtonElem
                                  type="button"
                                  iconLeft={CloseIcon}
                                  color="backgroundThird"
                                  onClick={deleteReaction}
                                  style={{
                                    minWidth: '46px',
                                    width: '46px',
                                    padding: '15px',
                                    marginTop: '26px',
                                  }}
                                />
                              </ReactionControlContainer>
                            );
                          },
                        )}
                      </ReactionWrapper>

                      <AddButton
                        type="button"
                        onClick={() =>
                          arrayHelpers.push({ emoji: '', quantity: '' })
                        }
                        tid="EVENT.CREATE.REACTION.ADD_REACTION"
                        color="backgroundThird"
                        iconRight={
                          theme.type === THEME_ENUM.LIGHT
                            ? addIconLight
                            : addIcon
                        }
                        disabled={isSubmitDisabled()}
                      />
                    </DoubleContainerElem>
                  )}
                />
              </GridElem>
            </React.Fragment>
          </GridElem>
        </form>
      </FormikProvider>
    </GridElem>
  );
};

const AddButton = styled(ButtonElem)`
  margin-top: 26px;
`;

const ReactionWrapper = styled(GridElem)``;

const ReactionControlContainer = styled(GridElem)`
  grid-template-columns: auto 46px;
  column-gap: ${Spacing(2)};
`;

const ReactionFieldContainer = styled(GridElem)`
  grid-template-columns: 1fr 1fr;
  column-gap: ${Spacing(2)};
`;

export const DeleteIconStyled = styled(DeleteIcon)`
  path {
    transition: all 0.2s;
  }
`;
