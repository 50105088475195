import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { WEBINAR_ITEM_DATA } from '../constant';
import { Spacing } from '../../../theme';

import { CardElem } from '../../../common/card';

import { useHistory } from 'react-router';
import { WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC } from '../../../page/webinar-settings';
import { TextElem } from '../../../common/text';
import { ChipElem } from '../../../common/chip';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';
import { FlexElem } from '../../../common/flex';
import { GridElem } from '../../../common/grid';

const Elem: React.FC<WEBINAR_ITEM_DATA> = ({
  id,
  name,
  speaker,
  plannedCount,
  comingNowCount,
  completedCount,
  autoCount,
  sessionCount,
  project,
}) => {
  const history = useHistory();

  const handleClick = () => {
    return history.push(WEBINAR_SETTINGS_PAGE_PATH_DYNAMIC(id));
  };
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <>
      {width < 800 ? (
        <MobileContent handleClick={handleClick}>
          <GridElem spacing={4}>
            <EllipsisContainerElem>
              <NameContainer>
                <TextElem type="semi-bold" tid={name} oneLine />
                <ChipElem color="active" tid={project.name} />
              </NameContainer>
            </EllipsisContainerElem>

            <FlexElem spacing={5}>
              <GridElem spacing={3} style={{ width: 'min-content' }}>
                <TextElem
                  tid="WEBINAR.LIST.SESSION"
                  tvalue={{ value: sessionCount }}
                  oneLine
                  style={{}}
                />

                <Status>
                  <TextElem tid={`${plannedCount}`} type="semi-bold" />
                  <TextElem tid="WEBINAR.STATUS.PLAN" />
                </Status>
              </GridElem>

              <GridElem spacing={3} style={{}}>
                <Status>
                  <TextElem
                    tid={`${comingNowCount}`}
                    color="ongoingStatus"
                    type="semi-bold"
                  />
                  <TextElem
                    tid="WEBINAR.STATUS.ONGOING"
                    color="ongoingStatus"
                  />
                </Status>
                <Status>
                  <TextElem
                    tid={`${completedCount}`}
                    color="success"
                    type="semi-bold"
                  />
                  <TextElem tid="WEBINAR.STATUS.COMPLETED" color="success" />
                </Status>
              </GridElem>
            </FlexElem>
          </GridElem>
        </MobileContent>
      ) : (
        <ContainerElem handleClick={handleClick}>
          {/* <EllipsisContainerElem> */}
          <NameContainer>
            <TextElem type="semi-bold" tid={name} oneLine />
            <ChipElem color="active" tid={project.name} />
          </NameContainer>
          {/* </EllipsisContainerElem> */}

          <TextElem
            tid="WEBINAR.LIST.SESSION"
            tvalue={{ value: sessionCount }}
            // oneLine
            style={{ marginLeft: '40px' }}
          />
          <Status>
            <TextElem tid={`${plannedCount}`} type="semi-bold" />
            <TextElem tid="WEBINAR.STATUS.PLAN" />
          </Status>
          <Status>
            <TextElem
              tid={`${comingNowCount}`}
              color="ongoingStatus"
              type="semi-bold"
            />
            <TextElem tid="WEBINAR.STATUS.ONGOING" color="ongoingStatus" />
          </Status>
          <Status>
            <TextElem
              tid={`${completedCount}`}
              color="success"
              type="semi-bold"
            />
            <TextElem tid="WEBINAR.STATUS.COMPLETED" color="success" />
          </Status>
        </ContainerElem>
      )}
    </>
  );
};

const MobileContent = styled(CardElem)<{}>`
  width: 100%;
  max-width: 100%;
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing(3)};
  vertical-align: middle;
  max-width: 300px;
`;

const Status = styled.div`
  display: flex;
  align-items: baseline;
  gap: 0.5em;
  @media screen and (width> 800px) {
    margin-left: ${Spacing(6)};
  }
`;

const ContainerElem = styled(CardElem)`
  align-items: center;

  display: grid;
  grid-column: span 5;
  grid-template-columns: subgrid;
`;

export { Elem as WebinarItemElem };
