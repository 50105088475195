import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { TextElem } from '../../text';
import { GridElem } from '../../grid';
import { FlexElem } from '../../flex';

export const CustomTooltip = (props: any) => {
  console.log(props);
  if (props.active && props.payload && props.payload.length) {
    return (
      <Container>
        <GridElem spacing={2}>
          <Flex spacing={1}>
            <TextElem
              size="verySmall"
              color="textFourth"
              style={{ height: '12px' }}
            >
              {props.label}
            </TextElem>
          </Flex>
          <Flex spacing={1}>
            <TextElem
              type="medium"
              size="verySmall"
              color="textFourth"
              style={{ height: '12px' }}
            >
              {props.payload[0].value}
            </TextElem>
          </Flex>
        </GridElem>
      </Container>
    );
  }

  return <></>;
};

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]};
  background: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  border-radius: 10px;
  padding: 8px;
`;

const Flex = styled(FlexElem)`
  align-items: end;
`;
