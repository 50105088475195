import React from 'react';
import styled from 'styled-components';
import { SURVEY_TYPE } from '../../../data/event/constant';
import { TextElem } from '../../../common/text';
import { COLOR_ENUM } from '../../../theme/color';
import { ContentContainerElem } from '../../../common/content-container';
import { FlexElem } from '../../../common/flex';
import { ReactComponent as AnswerIcon } from '../../../asset/svg/event/survey/answer.svg';
import { ReactComponent as PersonIcon } from '../../../asset/svg/event/survey/person.svg';
import { ReactComponent as AnswerActiveIcon } from '../../../asset/svg/event/survey/answerActive.svg';
import { GridElem } from '../../../common/grid';

export const Survey: React.FC<{
  data: SURVEY_TYPE;
  sendAnswer: (answer: number) => void;
}> = ({ data, sendAnswer }) => {
  return (
    <Container>
      <QuestionText>{data.question}</QuestionText>
      <AnswerListContainer spacing={2}>
        {data.options.map((option, index) => (
          <AnswerItem onClick={() => sendAnswer(index)} key={index}>
            <DotContainer>
              {index == data.answer ? <AnswerActiveIcon /> : <AnswerIcon />}
            </DotContainer>
            <FlexElem style={{ justifyContent: 'space-between' }}>
              <TextElem
                color={index == data.answer ? 'yellow' : 'textDefault'}
                type={index == data.answer ? 'semi-bold' : 'medium'}
              >
                {option.text}
              </TextElem>
              <Flex spacing={3}>
                <p>
                  <TextElem
                    type="medium"
                    color={index == data.answer ? 'textFourth' : 'textPrimary'}
                  >
                    {option.percentage}
                  </TextElem>
                  <TextElem
                    type="medium"
                    tid="EVENT.SURVEY.PERSENTAGE_COUNTER"
                    color={index == data.answer ? 'textPrimary' : 'textThird'}
                  />
                </p>
                <Flex spacing={1}>
                  <TextElem
                    type="medium"
                    color={index == data.answer ? 'textFourth' : 'textPrimary'}
                  >
                    {option.currentQuantity}
                  </TextElem>
                  <PersonIcon className="PersonIcon" />
                </Flex>
              </Flex>
            </FlexElem>
          </AnswerItem>
        ))}
      </AnswerListContainer>
    </Container>
  );
};

const Container = styled(ContentContainerElem)`
  @media screen and (width<=600px) {
    gap: 12px;
  }
`;

const Flex = styled(FlexElem)`
  width: fit-content;
`;

const AnswerListContainer = styled(GridElem)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350, 1fr));
  gap: 16px;
  width: 100%;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 8px;
  }
`;

const DotContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (width<=600px) {
  }
  rect,
  svg {
    height: 100%;
  }
`;

const AnswerItem = styled(Flex)`
  padding: 16px;
  border-radius: 10px;
  align-items: start;
  width: 100%;
  p,
  span {
    height: 14px;
  }
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};

  cursor: pointer;
  :hover {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
  }
  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  svg {
    height: 16px;
    width: 16px;
  }
  @media (max-width: 600px) {
    padding: 12px;
    svg {
      height: 15px;
      width: 15px;
    }
    p,
    span {
      height: 12px;
      font-size: 12px;
    }
  }
`;
const QuestionText = styled(TextElem)`
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
