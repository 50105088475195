import React from 'react';

import { LayoutSectionElem } from '../../common/layout-section';
import { HeaderContainer } from '../../epic/header';
import { LayoutAppElem } from '../../common/layout-app';
import { LayoutElem } from '../../common/layout';
import { PageElem } from '../../common/page';
import { MenuAppContainer } from '../../epic/menu-app';
import { RouteComponentProps, useLocation } from 'react-router';
import queryString from 'query-string';
import { SessionItemImportContainer } from '../../epic/session-item-import';

interface SessionImportPageProps
  extends RouteComponentProps<{
    sessionId: string;
    webinarId: string;
  }> {}

export const Page: React.FC<SessionImportPageProps> = ({ match }) => {
  const sessionId = match.params.sessionId;
  const webinarId = match.params.webinarId;

  const location = useLocation();
  const query = queryString.parse(location.search);
  console.log(sessionId, webinarId);
  return (
    <PageElem>
      <LayoutAppElem>
        <MenuAppContainer />

        <LayoutElem>
          <HeaderContainer path title="SESSION.IMPORT.HEADER" />

          <LayoutSectionElem spacing={8}>
            <SessionItemImportContainer
              id={sessionId}
              webinarId={webinarId}
              query={query}
            />
          </LayoutSectionElem>
        </LayoutElem>
      </LayoutAppElem>
    </PageElem>
  );
};
