import styled from 'styled-components';

import { Spacing } from '../../theme';
import { TextElem } from '../text';
import DatePicker from 'antd/es/date-picker';
import { ReactComponent as CloseIcon } from '../../asset/svg/common/close.svg';
import { ReactComponent as CalendarIcon } from '../../asset/svg/common/calendar.svg';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
  SIZE_FONT_DATA,
  SIZE_FONT_ENUM,
  SIZE_FONT_WEIGHT_DATA,
  SIZE_FONT_WEIGHT_ENUM,
} from '../../theme/size';
import { PROPS_TYPE } from './constant';
import dayjs from 'dayjs';

export const Elem: React.FC<PROPS_TYPE> = ({
  className,
  error,
  errorMessage,
  value,
  onChange,
  onBlur,
  title,
  name,
  format = 'YYYY-MM-DD HH:mm:ss',
  showTime = true,
  ...props
}) => {
  const handleChange = (e: any, stringValue: string) => {
    if (e) {
      e.target = {};
      e.target.name = name;
      e.target.value = stringValue;
      if (onChange) {
        onChange(e);
      }
    }
    if (e === null) {
      if (onChange) {
        onChange({ target: { name: name, value: '' } });
      }
    }
  };
  const id = new Date();
  return (
    <Container className={className} id={`${id}`}>
      <Content>
        {title && (
          <TextElem
            tid={title}
            color="textPrimary"
            style={{ height: '14px', display: 'block', marginBottom: '12px' }}
          />
        )}
        {/* @ts-ignore */}
        <Field
          showTime={showTime}
          needConfirm={false}
          format={format}
          suffixIcon={<CalendarIcon />}
          allowClear={{ clearIcon: <CloseIcon /> }}
          showNow={false}
          onChange={handleChange}
          onReset={handleChange}
          value={
            value && dayjs(value, format).isValid()
              ? dayjs(value, format)
              : null
          }
          // open
          getPopupContainer={() => document.getElementById(`${id}`)}
          {...props}
        />
      </Content>

      {(error || errorMessage) && (
        <TextElem size="alert" color="error">
          {errorMessage}
        </TextElem>
      )}
    </Container>
  );
};

const Content = styled.div`
  input {
    transition: all 0.2s;
  }
  span {
    transition: all 0.2s;
  }

  position: relative;
`;

const Container = styled.div`
  display: grid;
  grid-gap: ${Spacing(2)};

  .ant-picker-panel-container {
    .ant-picker-presets {
      /* min-height: 25rem !important; */
    }
    @media (max-width: 800px) {
      overflow: scroll !important;
      /* height: 400px; */
      .ant-picker-panel-layout {
        flex-direction: column !important;

        .ant-picker-presets {
          max-width: 100% !important;
          min-height: 10rem !important;
        }

        .ant-picker-panels,
        .ant-picker-datetime-panel {
          flex-direction: column !important;
        }
      }
    }
  }

  .ant-picker-cell-disabled {
    opacity: 0.3;
  }

  .ant-picker-time-panel-cell-disabled {
    opacity: 0.3;
  }

  .ant-picker-range-arrow {
    display: none !important;
  }
  .ant-picker-panel-container {
    background: transparent;
  }
  .ant-picker-super-prev-icon,
  .ant-picker-prev-icon,
  .ant-picker-super-next-icon,
  .ant-picker-next-icon {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
  }
  .ant-picker-panel {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.INPUT_ACTIVE]} !important;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
    width: 100%;
  }

  .ant-picker-clear {
    position: absolute;
    right: 0;
    inset-inline-end: 0px !important;
  }

  .ant-picker-suffix {
    position: absolute;
    right: 0;
  }

  .ant-picker-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }
  .ant-picker-content {
    th {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
    }
  }

  .ant-picker-header-view button:hover {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }
  .ant-picker-header-view {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }

  .ant-picker-cell-selected {
    div {
      background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
    }
  }

  .ant-picker-footer {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]} !important;
    background-color: ${({ theme }) =>
      theme[COLOR_ENUM.INPUT_ACTIVE]} !important;
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
  }
  .ant-picker-panel-layout {
    border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
    overflow: auto;
    border-radius: ${SIZE_BORDER_RADIUS_DATA[
      SIZE_BORDER_RADIUS_ENUM.DEFAULT
    ]}px;
  }
  .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
  }

  .ant-picker-time-panel-cell-selected > .ant-picker-time-panel-cell-inner {
    color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]} !important;
    background-color: ${({ theme }) => theme[COLOR_ENUM.BORDER]} !important;
  }
`;

const Field = styled(DatePicker)`
  height: 46px;
  box-sizing: border-box;

  cursor: pointer;
  height: 46px;
  :focus-visible {
    outline: none;
  }

  position: relative;
  .ant-picker-active-bar {
    display: none;
  }
  box-shadow: none !important;
  .ant-picker-input > input {
    ::placeholder {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_PLACEHOLDER]};
    }
    color: ${({ theme }) => theme[COLOR_ENUM.TEXT_DEFAULT]} !important;
  }

  .ant-picker-clear {
    inset-inline-end: 16px;
  }

  font-weight: ${SIZE_FONT_WEIGHT_DATA[SIZE_FONT_WEIGHT_ENUM.REGULAR]};

  font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.INPUT]}px;
  @media screen and (width<=600px) {
    font-size: ${SIZE_FONT_DATA[SIZE_FONT_ENUM.MOBILE_DEFAULT]}px;
  }
  line-height: 1em;
  text-align: start;

  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.DEFAULT]}px;
  width: 100%;

  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BORDER_DEFAULT]} !important;

  &:hover {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_HOVER]};
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
    *:has(~ &) {
      color: ${({ theme }) => theme[COLOR_ENUM.TEXT_FOURTH]} !important;
    }
  }

  &:focus-within {
    border-color: ${({ theme }) => theme[COLOR_ENUM.BORDER_ACTIVE]} !important;
    background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};
  }

  background: ${({ theme }) => theme[COLOR_ENUM.INPUT]};

  padding: 0 ${Spacing(4)};
`;
