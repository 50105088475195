import React from 'react';
import { EVENT_ITEM_DATA, EVENT_TYPE } from '../constant';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { EllipsisContainerElem } from '../../../common/ellipsis-container';

export const Message: React.FC<{
  data: EVENT_ITEM_DATA;
}> = ({ data }) => {
  const { message, person } = data;

  return (
    <>
      <EllipsisContainerElem>
        <TextElem
          type="medium"
          color="textFourth"
          oneLine
          style={{ paddingLeft: `${Spacing(5)}` }}
        >
          {message}
        </TextElem>
      </EllipsisContainerElem>
      <TextElem
        type="medium"
        color="textPrimary"
        oneLine
        style={{ paddingLeft: `${Spacing(5)}` }}
      >
        {person.name}
      </TextElem>
    </>
  );
};
