//@ts-nocheck

import { FormikValues } from 'formik';
import { GridElem } from '../../../common/grid';
import { FormElem } from '../../../common/form';
import { FakeField } from './fakeField';
import { TextElem } from '../../../common/text';
import { Form } from './form';
import { FormBorder } from '../component';
import { FlexElem } from '../../../common/flex';
import { ButtonElem } from '../../../common/button';
import ReservationIcon from '../../../asset/svg/session/reservation.svg';
import styled from 'styled-components';
import { Spacing } from '../../../theme';
import { COLOR_ENUM } from '../../../theme/color';
import { WEBINAR_ITEM_DATA_RAW } from '../../../data/webinar/constant';
import { DoubleContainerElem } from '../../../common/double-container';
import { PopoverElem } from './popover';
import Checkout from '../../../data/stripe/frame/provider';
import { useEffect } from 'react';

export const PaymentCreate: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  getFieldValue: Function;
  name?: string;
  phone?: string;
  setFormVisible: Function;
  formVisible: boolean;
  webinarData: WEBINAR_ITEM_DATA_RAW;
  isSubmitDisabled?: boolean;
  reservationId: string;
}> = ({
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  name,
  phone,
  setFormVisible,
  formVisible,
  webinarData,
  isSubmitDisabled,
  reservationId,
}) => {
  useEffect(() => {
    if (webinarData?.pixelId) {
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        'script',
        'https://connect.facebook.net/en_US/fbevents.js',
      );

      fbq('init', webinarData?.pixelId);
      fbq('track', 'PageView');
      fbq('track', 'AddToCard');
    }
    // Инициализация Pixel
  }, [webinarData?.pixelId]);
  return (
    <FormElem onSubmit={formik.handleSubmit}>
      <Container spacing={4}>
        <GridElem style={{ textAlign: 'start' }}>
          <p className="title">
            <TextElem
              color="textSecondary"
              type="medium"
              style={{ lineHeight: '150%' }}
              tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.PAYMENT"
            />
            <TextElem type="bold" color="active">
              {webinarData.price}$
            </TextElem>
          </p>

          <DoubleContainerStyled>
            <GridElem spacing={1}>
              <TextElem
                size="verySmall"
                color="textFourth"
                type="semi-bold"
                style={{ lineHeight: '150%' }}
                className="smallTitle"
                tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.BONUS.TITLE"
              />
              <Content>
                {webinarData?.bonuses?.map((bonus) => (
                  <TextElem
                    color="textFourth"
                    type="medium"
                    style={{ lineHeight: '150%' }}
                  >
                    {bonus}
                  </TextElem>
                ))}
              </Content>
            </GridElem>
            <GridElem spacing={1}>
              <TextElem
                size="verySmall"
                color="textFourth"
                type="semi-bold"
                style={{ lineHeight: '150%' }}
                className="smallTitle"
                tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.GARANT.TITLE"
              />
              <Content>
                {webinarData?.guarantes?.map((bonus) => (
                  <TextElem
                    color="textFourth"
                    type="medium"
                    style={{ lineHeight: '150%' }}
                  >
                    {bonus}
                  </TextElem>
                ))}
              </Content>
            </GridElem>
          </DoubleContainerStyled>
          <ContentBorder>
            <TextElem
              size="verySmall"
              type="semi-bold"
              style={{ lineHeight: '150%' }}
              tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.GARANT.TITLE_2"
            />
            <TextElem
              size="verySmall"
              color="textFourth"
              type="medium"
              style={{ lineHeight: '150%' }}
              tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.GARANT.DESCRIPTION"
            />
          </ContentBorder>
          <FormBorder spacing={4}>
            <GridElem spacing={2} style={{ justifyItems: 'center' }}>
              <TextElem
                type="semi-bold"
                color="textSecondary"
                tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.DESCRIPTION"
              />
            </GridElem>
            <GridElem spacing={3} style={{ maxWidth: '400px', margin: 'auto' }}>
              {formVisible ? (
                <GridElem spacing={4} style={{ textAlign: 'start' }}>
                  <Form
                    formik={formik}
                    isFieldError={isFieldError}
                    getFieldError={getFieldError}
                    getFieldValue={getFieldValue}
                  />
                  <ButtonElem
                    tid="SESSION.VIEWER.RESERVATION.MODAL.EDIT.BUTTON"
                    type="submit"
                    disabled={isSubmitDisabled}
                  />
                </GridElem>
              ) : (
                <FakeField
                  name={name}
                  phone={phone}
                  setFormVisible={setFormVisible}
                />
              )}
            </GridElem>
          </FormBorder>
          <Checkout reservationId={reservationId} />
          <PopoverElem />
        </GridElem>
      </Container>
    </FormElem>
  );
};
const DoubleContainerStyled = styled(DoubleContainerElem)`
  @media screen and (width<689px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled(GridElem)`
  padding: ${Spacing(3)};
  gap: ${Spacing(2)};
  border-radius: 12px;

  background-color: ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_PRIMARY]};
  span {
    font-size: 10px;
  }
  @media screen and (width>689px) {
    span {
      font-size: 14px;
    }
  }
`;

const ContentBorder = styled(Content)`
  background-color: ${({ theme }) => theme[COLOR_ENUM.TRANSPARENT]};
  border: 1px solid ${({ theme }) => theme[COLOR_ENUM.BACKGROUND_THIRD]};
  span {
    font-size: 10px;
  }
  @media screen and (width>689px) {
    span {
      font-size: 14px;
    }
    text-align: center;
    padding: ${Spacing(3)} ${Spacing(8)};
  }
`;

const Container = styled(GridElem)`
  .title {
    span {
      font-size: 12px;
    }
    @media screen and (width>689px) {
      span {
        font-size: 16px;
      }
    }
  }

  .smallTitle {
    font-size: 11px;
    @media screen and (width>689px) {
      font-size: 14px;
    }
  }
`;
